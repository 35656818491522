import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';

import {
  deleteGroupMemberRequest,
  getUserRequest,
  sendEmailCustomerRequest,
  updateGroupRequest,
  updateUserRequest,
} from '../redux/actions';
import Message from './Message';
import Loader from './Loader';
import { AppState, Member } from '../redux/types';
import { toast } from 'react-toastify';
import Confirm from './confirm/Confirm';

const MemberForm = ({ onSelect }: any) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [relative, setRelative] = useState<Member>();
  const user = useSelector((state: AppState) => state.user);
  const members = useSelector((state: AppState) => state.user.group?.members);
  const relatives = members?.filter((obj) => {
    return obj.role === 'member';
  }).sort((a, b) => Number(a.id) - Number(b.id));
  const qty = Math.max(
    0,
    5 -
    (relatives?.length ?? 0)
  );
  const { error, loading } = user;
  const [message, setMessage] = useState(
    user.role === 'member'
      ? ''
      : qty === 0
        ? 'Kiitos, ryhmäsi on täysi. Läheiset saavat sähköpostitse vahvistuksen rekisteröinnistä ja he voivat alkaa käyttää tilejään.'
        : qty > 0 && qty < 5
          ? `Läheinen on tallennettu ja hänelle on luotu tili. Voit halutessasi lisätä vielä ${qty} läheistä. Mikäli lisäsit jo kaikki haluamasi läheiset, siirry valmis-painikkeesta tarkastelemaan tilauksesi tietoja.`
          : ''
  );
  const [error2, setError2] = useState([]);
  const [firstPassword] = useState('');
  // const member = user.role === 'member' ? user : undefined
  const [formData, setFormData] = useState({
    id: user.role === 'member' ? user.id : undefined,
    username: user.role === 'member' ? user.username : '',
    email: user.role === 'member' ? user.email : '',
    password: '',
    firstName: user.role === 'member' ? user.firstName : '',
    mobileNumber: user.role === 'member' ? user.mobileNumber : '',
    role: 'member',
  });

  const [showPass, setShowPass] = useState(false);
  const showPassHandler = () => {
    setShowPass((current) => !current);
  };

  useEffect(() => {
    setMessage(
      user.role === 'member'
        ? ''
        : qty === 0
          ? 'Kiitos, ryhmäsi on täysi. Läheiset saavat sähköpostitse vahvistuksen rekisteröinnistä ja he voivat alkaa käyttää tilejään.'
          : qty > 0 && qty < 5
            ? `Läheinen on tallennettu ja hänelle on luotu tili. Voit halutessasi lisätä vielä ${qty} läheistä. Mikäli lisäsit jo kaikki haluamasi läheiset, siirry valmis-painikkeesta tarkastelemaan tilauksesi tietoja.`
            : ''
    );
  }, [qty, user.role]);

  useEffect(() => {
    let msg = [];
    if (
      formData.username.length > 0 &&
      formData.email.length > 0 &&
      formData.email !== formData.username
    ) {
      msg.push('Sähköpostit eivät täsmää');
    }
    if (formData.password.length && formData.password.length < 11) {
      msg.push('Salasana on liian lyhyt');
    }
    // @ts-ignore
    setError2(msg);
  }, [formData.username, formData.email, formData.password]);

  const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);
  const relativeCreateEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeCreateAccount);
  const confirmationEmailId = useSelector((state: AppState) => state.categories.category?.attributes.OrderProcessConfirmationToCustomer);

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const deleteHandler = async () => {
    dispatch(deleteGroupMemberRequest(relative?.id));
    setShowConfirm(false);
  }

  const handleRemove = (relative: Member) => {
    setRelative(relative)
    setShowConfirm(true);
  }

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // @ts-ignore
    const member_ids = new Set(members.map((member) => member.id));
    //console.log(qty)
    //console.log(member_ids)
    if (
      user.role !== 'member' &&
      // firstPassword &&
      member_ids?.size < 7 &&
      qty <= 5 &&
      qty >= 1 &&
      formData.firstName?.length &&
      formData.username?.length &&
      formData.username === formData.email
    ) {
      dispatch(
        updateGroupRequest({
          id: formData.id,
          username: formData.username,
          email: formData.email,
          firstName: formData.firstName,
          lastName: '',
          mobileNumber: formData.mobileNumber,
          password: firstPassword,
          role: formData.role,
          isAdmin: false,
          relativeCreateEmailId
        })
      );
      setFormData({
        id: user.role === 'member' ? user.id : undefined,
        username: user.role === 'member' ? user.username : '',
        email: user.role === 'member' ? user.email : '',
        password: '',
        firstName: user.role === 'member' ? user.firstName : '',
        mobileNumber: user.role === 'member' ? user.mobileNumber : '',
        role: 'member',
      });
    }

    if (user.role === 'member') {
      if (formData.username?.length && formData.username === formData.email) {
        dispatch(
          updateUserRequest({
            id: formData?.id,
            username: formData.username,
            firstName: formData.firstName,
            mobileNumber: formData.mobileNumber,
            email: formData.email,
            password: formData.password,
            relativeDoneEmailId
          })
        );
        setMessage('Kiitos, tallennus onnistui.');
      }
    }
  };

  const emailAndToOrderInfo = () => {
    dispatch(sendEmailCustomerRequest(user.email, user.firstName, confirmationEmailId));
    toast.success('Kaikki valmista!', {
      hideProgressBar: true,
      position: 'bottom-center',
      theme: 'colored',
    });

    setTimeout(function () {
      if (user?.id) {
        dispatch(getUserRequest(user.id));
      }
      onSelect('tilaus');
    }, 3000);
  };

  return (
    <>
      <Container fluid>
        <Row className='justify-content-md-left'>
          <Col xs={12} md={6}>
            {error && <Message variant='danger'>{error.message}</Message>}
            {!error && message && <Message variant='success'>{message}</Message>}
            {loading && <Loader />}
            <h1 className='member-form-header'>
              {user.role === 'member'
                ? 'Läheisen yhteystiedot'
                : '3 Lisää läheiset'}
            </h1>
          </Col>
        </Row>
        <Row className='justify-content-md-left'>
          <Col xs={12} md={6}>
            {user.role === 'customer' && (
              <div>
                <i
                  className='fas fa-exclamation-circle'
                  style={{
                    fontSize: 20,
                    margin: '0 4px 0 0',
                    backgroundColor: '#4e5180',
                    color: '#e5e8f1',
                    borderRadius: '50%',
                    height: 19,
                    width: 16,
                  }}
                />
                Huom! Tämä kohta ei koske kotisairaanhoidon tai palveluasunnon peruspalvelua. Paina suoraan Valmis.
              </div>
            )}
            {(qty > 0 || user.role === 'member') && (
              <Form onSubmit={submitHandler} className='user-form'>
                <Form.Group controlId='member-username'>
                  <Form.Label>*Sähköpostiosoite</Form.Label>
                  {user.role === 'member' && (
                    <Container className='introduction hint1'>
                      Huom! Lähetämme sinulle sähköpostia perhetiliin liittyen.
                      Jos käytät Gmail-osoitetta, tarkastathan myös "Tarjoukset"
                      ("Promotions") kansion.
                    </Container>
                  )}
                  <Form.Control
                    type='email'
                    placeholder='Sähköpostiosoite'
                    name='username'
                    value={formData.username}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId='member-email'>
                  <Form.Label>*Toista sähköpostiosoite</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Toista sähköpostiosoite'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                {user.role === 'member' && (
                  <>
                    <Form.Group controlId='member-password'>
                      <Form.Label>Vaihda salasana</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={showPass ? 'text' : 'password'}
                          placeholder='Uusi salasana'
                          name='password'
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <Button
                          variant='outline-secondary shadow-none show-password-btn'
                          onClick={showPassHandler}
                        >
                          <i
                            className={
                              showPass ? 'fas fa-eye-slash' : 'fas fa-eye'
                            }
                          ></i>
                        </Button>
                      </InputGroup>
                      <small className='for-example'>
                        Jos haluat vaihtaa salasanan, kirjoita uusi salasana
                        kenttään. Salasanan pituus vähintään 11 merkkiä.
                      </small>
                      <div className='show-password'>
                        Näet salasanasi klikkaamalla silmäkuvaketta
                      </div>
                    </Form.Group>
                  </>
                )}
                <Form.Group controlId='member-firstname'>
                  <Form.Label>*Etunimi</Form.Label>
                  <Form.Control
                    required
                    className='text-field'
                    type='text'
                    name='firstName'
                    placeholder='Etunimi'
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Form.Group>
                {user.role === 'member' && (
                  <>
                    <Form.Group controlId='member-mobile'>
                      <Form.Label>*Matkapuhelinnumero</Form.Label>
                      <Form.Control
                        required
                        className='text-field'
                        type='text'
                        name='mobileNumber'
                        placeholder='Matkapuhelinnumero'
                        value={formData.mobileNumber}
                        onChange={handleChange}
                      />
                      <small>Esimerkiksi +358 40 123456</small>
                    </Form.Group>
                  </>
                )}
                <Button
                  className='tallenna member-button'
                  type='submit'
                  disabled={
                    !formData.firstName?.length ||
                    !formData.username?.length ||
                    formData.username !== formData.email
                  }
                >
                  {user.role === 'member' ? 'Tallenna' : 'Tallenna läheinen'}
                </Button>
                {error2.map((msg) => (
                  <Message variant='danger'>{msg}</Message>
                ))}
              </Form>
            )}
            {user.role === 'customer' && (
              <>
                <p className='user-form'>
                  Kun olet lisännyt kaikki valitsemasi läheiset perhetilille paina
                  Valmis
                </p>
                <Button
                  type='button'
                  className='tallenna'
                  onClick={emailAndToOrderInfo}
                >
                  Valmis
                </Button>
              </>
            )}
          </Col>
          {user.role !== "member" && relatives?.length > 0 &&
            <Col xs={12} md={6}>
              <h2 className="relatives-list-header">Lisätyt läheiset</h2>
              <ol className="relatives-list">
                {
                  relatives?.map((relative, idx) => (
                    <li key={idx}>
                      <div className="relative-line">
                        <span>{relative.firstName}</span>
                        <span onClick={() => handleRemove(relative)} style={{ cursor: "pointer" }}>
                          <i
                            className="fas fa-trash" />
                        </span>
                      </div>
                    </li>
                  ))
                }
              </ol>
            </Col>
          }
        </Row>
      </Container>
      <Confirm show={showConfirm} handler={setShowConfirm} message={`Oletko varma, että haluat poistaa läheisen ${relative?.firstName}`} onYes={deleteHandler} />
    </>
  );
};
export default MemberForm;
