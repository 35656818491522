import { HashLink as Link } from "react-router-hash-link";

const youtubeLink = "https://www.youtube.com/watch?v=8GAQYE12YXQ";
const ServicesInfo2 = () => {
    return (
        <>
            <div className="global-box-line-container">
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Tabletti, liittymä, jalusta</div>
                        <ul className="services-info-text">
                            <li>Samsung Galaxy Tab (kaksi kokoa) tai Apple iPad </li>
                            <li>Liittymä, rajaton data </li>
                            <li>
                                Jalusta -{" "}
                                <a href={youtubeLink} className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                    <strong>katso video</strong>
                                </a>
                            </li>
                        </ul>
                        <p className="options">
                            <Link className="linkki global-text-dark" to="/palvelut#palvelut" style={{ fontWeight: 500 }}>
                                Valitse seniorin toimintakyvyn mukaan tabletti Huoleton, Helppo tai Mestari.
                            </Link>
                        </p>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/tabletti-senorille.webp" alt="Henkilö pitelee tablettia kädessään." className="global-image" />
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/senioritabletti.webp" alt="Henkilö käyttää tabelttia ja kosketusnäyttökynää." className="global-image" />
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Yksilöllisesti muokattu tabletti ja Digihappy kotinäkymä</div>
                        <ul className="services-info-text">
                            <li>Senioriystävälliset asetukset, sovellukset ja kontaktit valmiina </li>
                            <li>Seniorille, joka ei itse koske tablettiin voi valita vain videopuhelunäkymän</li>
                            <li>
                                Aktiiviselle seniorille lisäksi mm. radiot, äänikirjat, lehdet,{" "}
                                <a href="https://areena.yle.fi/" className="linkki" target="_blank" rel="noreferrer">
                                    Yle Areena
                                </a>
                                , pelit tai sähköposti - ja mahdollisuus lisätä omia appeja
                            </li>
                            <a href={youtubeLink} className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                <strong>Katso video</strong>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Helppo ja turvallinen videopuhelu</div>

                        <ul className="services-info-text">
                            <li>Digihappy videoappi on seniorilla tabletissa ja läheisellä kännykässä</li>
                            <li>Kun läheinen soittaa, seniorin video avautuu haluttaessa ilman kosketusta</li>
                            <li>Seniori soittaa koskemalla läheisen kuvaa</li>
                            <li>Turvallinen, vain perhetilin jäsenet voivat soittaa</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/hyvä-senioritabletti.webp" alt="Nainen käyttää tablettia maatessaan sängyssä" className="global-image" />
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/4.png" alt="H" className="global-image global-image-contain" />
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Perhetili videopuheluihin</div>
                        <ul className="services-info-text">
                            <li>Saat palveluun kuusi perheenjäsentä tai ystävää. Jäsen voi soittaa videopuhelun, joka avautuu haluttaessa automaattisesti seniorin tabletissa. Jäsenen kuva on seniorin videopuhelunäkymässä.</li>
                            <li>Jäsenen käytössä on it-tuki.</li>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">It-tuki, etähallinta, tietoturva, päivitykset</div>
                        <ul className="services-info-text">
                            <li>Aloitusneuvonta</li>
                            <li>Jatkuva it-tuki puhelimitse tai viesteillä</li>
                            <li>Tabletin etähallinta, myös ilman seniorin aktiivista osallistumista </li>
                            <li>Tietoturva ja päivitykset</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/ittuki-seniorille.webp" alt="Kaksi naista istuu sohvalla keskittyneinä tabletin näyttöön. He soittavat it-tuelle." className="global-image" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesInfo2;
