import TextOnImage from "../components/TextOnImage";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import celia from "../images/partners/celia.png";
import villa_tapiola from "../images/partners/villa_tapiola.png";
import ainola from "../images/partners/ainola.png";
import loistohoiva from "../images/partners/loistohoiva.png";
import elsa from "../images/partners/elsa.png";
import lehmuskartano from "../images/partners/lehmuskartano.png";
import uvky from "../images/partners/uvky.jpg";
import domacare from "../images/partners/domacare.png";
import helykodit from "../images/partners/helykodit.png";
import hyvan_mielen_kotihoito from "../images/partners/hyvan_mielen_kotihoito.png";
import tunaberg from "../images/partners/tunaberg.jpg";
import linkedin from "../images/linkedin.png";
import aboutus1 from "../images/aboutus1.jpg";

const AboutUs = () => {
    const [video, setVideo] = useState(false);
    const [frameHeight, setFrameHeight] = useState(550);
    const myRef = useRef<HTMLHeadingElement>(null);

    return (
        <>
            <TextOnImage
                height={`calc(100vh - 66px)`}
                width="100vw"
                text1={"Meistä"}
                backgroundImage={"https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/about_us_cover.jpg"}
                backgroundSize="cover"
                backgroundPosition="center center"
                backgroundRepeat="no-repeat"
                imgClassName="forcompaniespic"
                className1="aboutus-text-on-image col-md-8 col-12"
            />
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Miksi Digihappy?
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Onnellinen vanhuus on aktiivisia ihmissuhteita, mielekästä tekemistä ja turvallisuutta. Digihappy palveluilla yhteyden läheiseen saa aina. Valmiin palvelun voi valita seniorin toimintakyvyn mukaan ja omaisen tai
                        hoitajan ei tarvitse toimia it-tukena.
                    </p>
                </div>
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Katso videolta, miten Huoleton Senioritabletti helpottaa Railin ja eri kaupungissa asuvan tyttären yhteydenpitoa. Ja miten Seniorihotelli Ainolan johtaja Asta Martin on kokenut Digihappy palvelun.
                    </p>
                </div>
            </section>
            <section className="texts-for-companies">
                {video ? (
                    <iframe
                        height={frameHeight}
                        src={`https://www.youtube.com/embed/fprEcs7sQKc?autoplay=1&rel=0&color=white&modestbranding=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Digihappy Senioritabletit"
                        style={{
                            margin: "0 auto",
                            width: "100%",
                        }}
                    />
                ) : (
                    <div
                        style={{
                            position: "relative",
                        }}
                        ref={myRef}
                    >
                        <img
                            id="video-cover"
                            src="https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/fprEcs7sQKc_maxresdefault.jpg"
                            alt="Digihappy Senioritabletit"
                            style={{
                                height: "auto",
                                width: "100%",
                            }}
                        />
                        <button
                            id="play"
                            className="play-btn"
                            onClick={() => {
                                // @ts-ignore
                                setFrameHeight(myRef?.current?.clientHeight);
                                setVideo(true);
                            }}
                        >
                            <i
                                className="fas fa-play"
                                style={{
                                    color: "#ffffff",
                                    fontSize: "6rem",
                                }}
                            />
                        </button>
                    </div>
                )}
            </section>
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Digihappyn tarina
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy on suomalainen yritys, jonka Tiina perusti havaittuaan, kuinka vaikea oli pitää yhteyttä hoivakodissa asuvaan isään. Tiina on lääkäri, ktm ja yrittäjä{" "}
                        <a className="linkki" href="https://www.linkedin.com/in/tiinaleivomd/?originalSubdomain=fi" target="_blank" rel="noreferrer">
                            <img
                                src={linkedin}
                                alt="LinkedIn"
                                style={{
                                    height: "1.5rem",
                                    marginBottom: "0.2rem",
                                }}
                            />
                        </a>
                        , joka on kerännyt osaavan ydintiimin ja taitavat suomalaiset ja kansainväliset yhteistyökumppanit. Teemme palveluja senioreille, perheille ja hoivapalvelujen tuottajille. Toimistomme on Otaniemessä Espoossa.
                    </p>
                </div>
            </section>

            <section
                className=""
                style={{
                    margin: "2rem",
                }}
            >
                <Row className="align-items-end justify-content-center">
                    <Col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: "url(https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/Tiina%2C+new.png)",
                                }}
                                className="aboutus-photo-img-1"
                            />
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Tiina</b>
                            <br />
                            Digihappyn perustaja
                        </div>
                    </Col>
                    <Col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: "url(https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/johanna.jpeg)",
                                }}
                                className="aboutus-photo-img-1"
                            />
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Johanna</b>
                            <br />
                            UX, COO, Asiakaskokemus ja toiminnot
                        </div>
                    </Col>
                    <Col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: `url(${aboutus1})`,
                                    width: "100%",
                                }}
                                className="aboutus-photo-img-1"
                            />
                        </div>
                        <div className="aboutus-photo-caption">CTO Joel, Johanna ja Tiina</div>
                    </Col>
                </Row>
                <Row className="align-items-end justify-content-center">
                    <Col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: "url('/assets/images/about/digihappy-team.webp')",
                                    width: "100%",
                                }}
                                className="aboutus-photo-img-2"
                                aria-label="Kolme ihmistä kypärät päässä ajamassa polkupyörillä järven rannalla."
                            />
                        </div>
                        <div className="aboutus-photo-caption" style={{ maxWidth: "100%" }}>
                            Digihappy maastopyöräilemässä Oikealla Rebecka Ruotsinkielinen palvelu- ja, somevastaava
                        </div>
                    </Col>
                    <Col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: "url('/assets/images/about/digihappy-programmer.webp')",
                                }}
                                className="aboutus-photo-img-1"
                                aria-label="Mies harmaassa paidassa istuu kivellä veden äärellä"
                            />
                        </div>
                        <div className="aboutus-photo-caption">
                            Brian
                            <br />
                            Devops
                        </div>
                    </Col>
                    <Col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div
                                style={{
                                    backgroundImage: "url(https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/Creole.png)",
                                }}
                                className="aboutus-photo-img-2"
                            />
                        </div>
                        <div className="aboutus-photo-caption">Creole studios, Pavle A, Peter, Orest Full-stack kehitys</div>
                    </Col>
                </Row>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Digihappy on Vuoden Digitaalinen teko finalisti
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy valittiin vuoden Digitaalinen teko 2022 finaaliin kolmen parhaan joukkoon. Tuomaristo perusteli Digihappyn valintaa seuraavasti: ”Suomi ikääntyy ja vanhukset ovat usein heikoimmilla digitalisaatiossa.
                        Digihappy on kehitetty juuri ikäihmisten lähtökohdista ja räätälöity heidän tarpeisiinsa. Yhteydenpitopalvelun kokonaisuus laitteineen ja ohjelmistoineen sekä sen käytön helppous ja selkeys vakuuttavat.” Lue lisää{" "}
                        <a target="_blank" className="linkki" rel="noreferrer" href="https://www.atea.fi/vuoden-digitaalisin-teko-2022/">
                            https://www.atea.fi/vuoden-digitaalisin-teko-2022/
                        </a>
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Vastuullisuus on kestäviä tuotteita, asiakkaan huomioimista ja tietoturvaa
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Vastuullisuus tarkoittaa meillä sitä, että käytämme vain korkealaatuisia laitteita ja tarvikkeita, joilla on pitkä käyttöikä ja joita voimme huoltaa ja kierrättää. Emme suoramyy vanhukselle, joka ei ymmärrä, mitä hän
                        on ostamassa. Emme sido asiakasta pitkillä sopimuksilla. Tietoturva on meille aidosti tärkeää kaikessa tekemisessä. Puhelinmyyjät eivät voi soittaa Digihappy videopuheluita. tablettiin. Ja tavoitteemme on tehdä niin
                        kustannustehokas palvelu, että mahdollisimman moni voi hankkia sen.
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Yhteistyö on meille tärkeää
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy tekee yhteistyötä hoivapalveluyritysten, julkisten toimijoiden ja yhdistysten kanssa. Kehitämme palveluja huomioiden käyttäjien toiveet. Meillä on syvää osaamista tutkimustyöstä, jota myös teemme yhdessä
                        kumppaniemme kanssa. Olemme kiitollisia, että olemme saaneet tehdä yhteistyötä monen edelläkävijäorganisaation kanssa.
                    </p>
                </div>
            </section>

            <section className="texts-for-companies">
                <Row className="align-items-baseline justify-content-around">
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/villatapiola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src={villa_tapiola} alt="Villa Tapiola" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/ainola/ainola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src={ainola} alt="Ainola Seniorihotelli" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://loistohoiva.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={loistohoiva} alt="Loistohoiva" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hoivakodit.luvn.fi/fi-FI/hoivakodit/d19f24f84b" className="linkki" target="_blank" rel="noreferrer">
                            <img src={lehmuskartano} alt="Lehmuskartano" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.celia.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={celia} alt="Celia" className="aboutus-partners" />
                        </a>
                    </Col>
                </Row>
                <Row className="align-items-baseline justify-content-around">
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.uvky.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={uvky} alt="UVKY" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://domacare.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={domacare} alt="Domacare" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.helykodit.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={helykodit} alt="Helykodit" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hyvanmielenkotihoito.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={hyvan_mielen_kotihoito} alt="Hyvän Mielen Kotihoito" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.elsa.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={elsa} alt="Elsa" className="aboutus-partners" />
                        </a>
                    </Col>
                </Row>
                <Row className="align-items-baseline justify-content-around">
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoitajasi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/hoitajasi.png"} alt="Hoitajasi" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoivapalveluanne.com/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/hoivapalveluanne.webp"} alt="Hoivapalveluanne" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.seniorhem.fi/tunaberg-seniorhem-och-tunaro-vardhem/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={tunaberg} alt="Tunaberg seniorhem" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://posifon.se/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/posifon.png"} alt="Posifon" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.sanitum.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/Sanitum.jpg"} alt="Sanitum" className="aboutus-partners" />
                        </a>
                    </Col>
                </Row>
                <Row className="align-items-baseline justify-content-around">
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.omegayksi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/omegayksi.png"} alt="Omegayksi" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://satakunnanhyvinvointialue.fi/palvelut/ikaantyneet/kotona-asumisen-tukipalvelut/funteeraamo/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/Funteeraamo.png"} alt="Funteeraamo" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.jomala.ax/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/jomala.png" alt="Jomala" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://agrid.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/v2/a_grid.png" alt="A Grid" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.arjenkotipalvelut.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/v2/arjen-kotipalvelut-Oy-logo.jpg" alt="ARJEN KOTIPALVELUT" className="aboutus-partners" />
                        </a>
                    </Col>
                </Row>
                <Row className="align-items-baseline justify-content-around">
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.brando.ax/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/v2/brando.png"} alt="Brando" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.finstrom.ax/" className="linkki" target="_blank" rel="noreferrer">
                            <img src={"/assets/images/about/logos/v2/finnström_kommun.png"} alt="Finnström Kommun" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="http://www.hoivasydan.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/v2/hoivasydan.jpg" alt="HoivaSydän" className="aboutus-partners" />
                        </a>
                    </Col>
                    <Col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://liinunpalvelut.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/v2/liinunpalvelut.png" alt="liinunpalvelut.fi" className="aboutus-partners" />
                        </a>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default AboutUs;
