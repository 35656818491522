import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ConfirmProps {
    show: boolean,
    handler: (show: boolean) => void;
    message: string,
    onYes: VoidFunction,
}
export default function Confirm({ show, handler, message, onYes }: ConfirmProps) {
    const handleClose = () => {
        handler(false);
    }
    return (
        <Modal show={show} onHide={handleClose} className="confirm-modal">
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Takaisin
                </Button>
                <Button variant="primary" onClick={onYes}>
                    Kyllä, poista
                </Button>
            </Modal.Footer>
        </Modal>
    );
}